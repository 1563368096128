import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'
import PageHeading from '../components/global/PageHeading'
import PartCategories from '../components/parts/PartCategories'
import PartsTable from '../components/parts/PartsTable'
import PageLink from '../components/global/PageLink'
import LeftArrowIcon from '../graphics/icons/left-arrow-icon.svg'
import pluralize from 'pluralize'

const PartsTypePageComponent = ({ data, location }) => {

  const tableData = data.parts.edges.map(part => ({
    number: part.node.data.Part_Number,
    query: `<strong>${part.node.data.Part_Number}</strong><small>${part.node.data.Part_Description}</small>`,
    variation: part.node.data.Part_Variation ? part.node.data.Part_Variation[0].data.Variation : null,
    description: part.node.data.Part_Description,
    eq1: part.node.data.Part_Equivalent_1,
    eq2: part.node.data.Part_Equivalent_2,
    eq3: part.node.data.Part_Equivalent_3,
  }))

  const type = data.type.edges.map(type => ({
    name: pluralize(type.node.data.Type_Name),
  }))[0]

  const categorySlug = location.pathname.split('/')[2]
  const category = titleCase(categorySlug.replace(/-/g,' '))

  function titleCase(str)
  {
   return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  return (
    <Layout>
      <Helmet titleTemplate="%s - Techna NDT">
      <title>{type.name}</title>
        <meta name="description" content="{category.description}" />
      </Helmet>
      <PageHeading tw="hidden lg:block" title="Parts Database" />
      <Container>
        <div tw="flex flex-no-wrap w-full">
          <PartCategories />
          <Col tw="flex-1 pt-10 overflow-auto">
            <div tw="lg:pl-4">
              <div tw="flex">
                <PageLink tw="w-auto flex mb-4 text-sm md:text-base" to={`/parts/${categorySlug}`}><LeftArrowIcon tw="mr-1" />{category}</PageLink>
              </div>
              <PartsTable
                data={tableData}
                category={type}
              />
            </div>
          </Col>
        </div>
      </Container>
    </Layout>
  )
}

PartsTypePageComponent.propTypes = {
  helmet: PropTypes.object,
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
}

const PartsTypePage = styled(PartsTypePageComponent)`
  ${tw``}
`

export default PartsTypePage

export const pageQuery = graphql`
  query PartsTypeQuery($id: String!) {
    parts: allAirtable(
      filter: {
        table: { eq: "Parts" }
        data: { Part_Type: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      edges {
        node {
          data {
            Part_Number
            Part_Variation {
              data {
                Variation
              }
            }
            Part_Description
            Part_Equivalent_1
            Part_Equivalent_2
            Part_Equivalent_3
          }
          id
        }
      }
    }
    type: allAirtable(
      filter: { table: { eq: "Types" }, id: { eq: $id } }
    ) {
      edges {
        node {
          id
          data {
            Type_Name
          }
        }
      }
    }
  }
`
